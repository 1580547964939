import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { CategorySearch, category_Edit, create_category, getAll_Category } from "./Category_api";
import showNotification from "../../../../../components/extras/showNotification";
import Icon from "../../../../../components/icon/Icon";



const initialState={
 all_category:[]
}



//********************** */ get all
const fetch_allCategory=createAsyncThunk(
    'category/fetch_allCategory',
    async(modeid:any)=>{
        try {
            const response= await getAll_Category(modeid)
         
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )
// **********************add
const add_category= createAsyncThunk('category/add_category', async (data: any) => {
	try {
		const response = await create_category(data);
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
        //  await getAll_Category(modeid)
		
		return response.data
	  }
	} catch (error) {
		
		 console.log(error)
	}
});
const edit_category = createAsyncThunk('category/edit_category', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await category_Edit( modify,id as any );
		
		if(response?.ok as any){
			// await getAll_Category()
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const search_category =createAsyncThunk('category/search_category',async(name:any)=>{
	try {
		const response=await CategorySearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


const CategorySlice = createSlice({
	name: 'category',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetch_allCategory.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_category=action.payload
		});
		builder.addCase(search_category.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			state.all_category=action.payload
		});
		
	},
});

export {fetch_allCategory,add_category,edit_category,search_category}
export default CategorySlice.reducer