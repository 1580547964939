import { BASE_URL } from '../../../../ApiBaseUrl';
import axios from 'axios';

// ResortGetAll start

export const ResortGetAll = async (token: string) => {
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    const response = await fetch(
      `${BASE_URL}/stores/getAll?page_size=100&page_number=1`,
      options,
    );
    const data = await response.json();
    console.log(data.data);
    return data.data;
  } catch (error) {
    console.log('error', error);
  }
};

// ResortGetAll end

// ResortAdd start

export const ResortAdd = async (token: string, formdata: any) => {
  const options = {
    method: 'POST',
    headers: {
      'x-access-token': token,
    },
    data: formdata,
  };

  try {
    const response = await axios.post(`${BASE_URL}/stores/create`, formdata, options);
    // You can do something with the response if needed.
    console.log(response.data);
  } catch (error) {
    console.log(error);
  }
};

// ResortAdd end

// Resort get by id start

export const ResortView = async (token: string, id: string) => {
  const options = {
    method: 'GET',
    headers: {
      'content-type': 'application/json',
      'x-access-token': token,
    },
  };

  try {
    const response = await fetch(`${BASE_URL}/stores/getById/${id}`, options);
    const data = await response.json();
    console.log(data.data);
    return data.data;
  } catch (error) {
    console.log(error);
  }
};

// Resort get by id end
