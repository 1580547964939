import { BASE_URL } from "../../../../../ApiBaseUrl";



// get allitems


export const getAll_tags = async (modeid:any) => {
	const options = {
		method: 'GET',
		headers: {
			'content-type': 'application/json',
		},
	};

	try {
		const response = await fetch(`${BASE_URL}Tags/getall/${modeid}?page_no=1&page_size=100`,options);
		const data = await response.json();
		console.log(data.data);
		return data.data;
	} catch (error) {
		console.log('error', error);
	}
};

export const create_Tags = async ( data: any) => {

	
	


	
	const options = {
		method: 'POST',
        headers:{
            'Content-Type':'application/json'
        },
		body: JSON.stringify(data),
	};

	try {
		const response = await fetch(`${BASE_URL}/Tags/create`, options);
		return await response.json()
	} catch (error) {
		console.log(error)
	}
};