import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { ITEM_Edit, create_item, getAll_Item, itemFilter, itemGetid, itemSearch, item_delete } from "./ItemApi";


const initialState={
 all_items:[],
 current_items:[]
}



//********************** */ get all
const fetch_allItems=createAsyncThunk(
    'items/fetch_allAddons',
    async(modeid:any)=>{
        try {
            const response= await getAll_Item(modeid)
         
            return response
        } catch (error) {
            console.log(error)
        }
    }
 )
 const add_item = createAsyncThunk('items/add_item', async (data: any) => {
	try {
		const response = await create_item(data);
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
        //  await getAll_Item(modeid)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});
// item
const item_get_id= createAsyncThunk('items/item_get_id', async (id:any) => {
	const response = await itemGetid(id);
	console.log(response);
	return response; // Assuming the response is an array of drivers
});
/*************************delete api****************** */
const delete_item =createAsyncThunk(
	'items/delete_item', async (id:any)=>{
		try {
			
			const response =await item_delete(id as any)
			console.log(response)
			if(response?.statusCode ===200){
				// await getAll_Item()
			 }
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)

const edit_ITEM = createAsyncThunk('items/edit_ITEM', async (data: any) => {
	console.log(data)
	const{modify,id}=data
	try {
		const response = await ITEM_Edit( modify,id as any );
		
		if(response?.ok as any){
			// await getAll_Item()
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});
const search_item =createAsyncThunk('items/search_item',async(name:any)=>{
	try {
		const response=await itemSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})

const filter_item =createAsyncThunk('items/filter_item',async(name:any)=>{
	try {
		const response=await itemFilter(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})

const itemsSlice = createSlice({
	name: 'items',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetch_allItems.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			console.log(action.payload)
			state.all_items=action.payload
		});
		builder.addCase(item_get_id.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			console.log(action.payload)
			state.current_items=action.payload
		});
		builder.addCase(search_item.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			console.log(action.payload)
			state.all_items=action.payload
		});
		builder.addCase(filter_item.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
			console.log(action.payload)
			state.all_items=action.payload
		});
		
		
	},
});

export {fetch_allItems,add_item,delete_item,item_get_id,edit_ITEM,search_item,filter_item}
export default itemsSlice.reducer