import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll_Order, Order_Search, Order_Viewid, Order_delete, create_order } from "./OrderApi";

// local storege
// const token: string | null = localStorage.getItem('token');
// console.log(token);


interface OrderState
{
    allOrder:[],
    currentOrder:[]
    
}
const initialState: OrderState={
    allOrder:[],
    currentOrder:[]
 
}


// ****************get all*********************
 const fetch_OrderAll=createAsyncThunk(
    'Order/fetch_OrderAll',
    async(token:any)=>{
        console.log(token)
    
        try {
            const response=await GetAll_Order(token as any)
            console.log(response)
            return response
            
        } catch (error) {
         console.log(error)   
        }

    })
    /*************************delete api****************** */
const delete_order =createAsyncThunk(
	'driver/deleteDriver', async (token:any,id:any)=>{
		try {
			
			const response =await Order_delete(token)
			console.log(response)
			if(response?.statusCode ===200){
				await GetAll_Order(token)
			 }
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)


    const add_order = createAsyncThunk('Order/add_order', async (data: any) => {
        try {
            const response = await create_order(data);
            
          if(response?.statusCode ===201){
            const ids=response.data._id
            const dta={token:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NGYwNmNhMDMxOWUzNDIzOTI5NzAzYjQiLCJpYXQiOjE2OTM0NzgzMzh9.wzfeZr5ZyMYoQIRV4QLcK1R0DLO8zTJgbkiMvQqI8zA",id:ids}
              console.log(response,"response")
             const dataaa = await Order_Viewid(dta as any)
              return dataaa;
          }
        } catch (error) {
             console.log(error)
        }
    });

    const order_View = createAsyncThunk('order/order_View', async (data:any,id:any) => {
        const response = await Order_Viewid(data);
        console.log(response);
        return response; // Assuming the response is an array of drivers
    });


    const search_order =createAsyncThunk('order/search_order',async(name:any)=>{
        try {
            const response=await Order_Search(name)
            console.log(response)
            return response
        } catch (error) {
            console.log(error)
        }
    })
    

const OrderSlice=createSlice({
    name:'OrderSlice',
    initialState,
    reducers:{},
    extraReducers:(builder)=>{
        builder.addCase(add_order.fulfilled,(state,{payload})=>{
            state.currentOrder=payload

        });
        builder.addCase(fetch_OrderAll.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
            console.log(action.payload)
			state.allOrder=action.payload
		});
        builder.addCase(order_View.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
            console.log(action.payload)
			state.currentOrder=action.payload
		});
        
        builder.addCase(search_order.fulfilled, (state, action: any) => {
			// state.allDriver .push(...action.payload);
            console.log(action.payload)
			state.allOrder=action.payload
		});
    }
})


export default OrderSlice.reducer;
export {fetch_OrderAll,add_order,delete_order,order_View,search_order}