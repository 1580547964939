import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { DriverSearch, Driverdelete, driverAdd, driverEdit, driverGetAll, driverUpdateimages, driverViewid } from './DriverApi';
import moment from 'moment';


interface Driver {
	fullname: string;
}

interface DriverState {
	allDriver: Driver[];
	currentDriver:Driver[]
	searchDriver:Driver[]
}
const initialState: DriverState = {
	allDriver: [],
	currentDriver:[],
	searchDriver:[]
};

// local storege
const token: string | null = localStorage.getItem('token');
console.log(token);
//





//get fetchdriverAll
const fetchdriverAll = createAsyncThunk('driver/fetchdriverAll', async (modeid:any) => {
	const response = await driverGetAll(modeid,token as string);
	const data=response.map((item:any)=>{
         item.dob=moment( item.dob).format('YYYY-MM-DD')
		return item
	})
	console.log(response);
	return data; // Assuming the response is an array of drivers
});





// add adddriverAll

const adddriverAll = createAsyncThunk('driver/adddriverAll', async (data: any) => {
	try {
		const response = await driverAdd(token as string, data );
		// return response;
	  console.log(response?.ok)
	  if(response?.ok as any){
        //  await driverGetAll(token as string)
		
	  }
	} catch (error) {
		 console.log(error)
	}
});

// editdriver
const editdriverAll = createAsyncThunk('driver/adddriverAll', async (data: any) => {
	console.log(data)
	const{value,id}=data
	try {
		const response = await driverEdit(token as string, value,id as any );
		
		if(response?.ok as any){
			// await driverGetAll(token as string)
			return response;
		   
		 }
	} catch (error) {
		 console.log(error)
	}
});

const updatedriverImages=createAsyncThunk('driver/updatedriverImages',async(data:any)=>{
	console.log(data)
	const{modifyImages,id}=data
	try {
		const response=await driverUpdateimages(modifyImages,id)
		return response
	} catch (error) {
		console.log(error)
	}
})


//****************search api******* */
const searchdriver =createAsyncThunk('driver/serachdriver',async(name:any)=>{
	try {
		const response=await DriverSearch(name)
		console.log(response)
		return response
	} catch (error) {
		console.log(error)
	}
})


/*************************delete api****************** */
const deleteDriver =createAsyncThunk(
	'driver/deleteDriver', async (id:any)=>{
		try {
			
			const response =await Driverdelete(id as any)
			console.log(response)
			if(response?.statusCode ===200){
				// await driverGetAll(token as string)
			 }
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)








// get by id

const driverView = createAsyncThunk('driver/driverView', async (id:any) => {
	const response = await driverViewid(token as string,id);
	console.log(response);
	return response; // Assuming the response is an array of drivers
});










const driverSlice = createSlice({
	name: 'driverSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		
		builder.addCase(fetchdriverAll.fulfilled, (state, action: any) => {

			state.allDriver=action.payload
		});
		builder.addCase(searchdriver.fulfilled, (state, action: any) => {
			
			state.allDriver=action.payload
		});
		builder.addCase(driverView.fulfilled, (state, action: any) => {
			state.currentDriver=action.payload


		});
		// builder.addCase(adddriverAll.fulfilled, (state, action: any) => {
        //     state.allDriver.filter((items:any)=>items._id!==action.payload)
		// });
	},
});

export default driverSlice.reducer;
export { fetchdriverAll, adddriverAll,editdriverAll,updatedriverImages,deleteDriver ,driverView,searchdriver};
