import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { Mode_Edit, Mode_add, Mode_all, Mode_delete } from './ModeApi';

const initialState = {
  allModes: [],
  loading: false,
  error: null,
};

// Fetch modes
const fetchModes = createAsyncThunk('modes/fetchModes', async () => {
  try {
    const response = await Mode_all();
    const data = response.map((item:any) => ({
      ...item,
      dob: moment(item.dob).format('YYYY-MM-DD'),
    }));
    return data;
  } catch (error) {
    throw error;
  }
});

// Add mode
const addMode = createAsyncThunk('modes/addMode', async (data:any, { dispatch }) => {
  try {
    const response = await Mode_add(data);
    if (response?.ok) {
      dispatch(fetchModes());
      return response; // You can return the response if needed
    }
  } catch (error) {
    throw error;
  }
});

// Edit mode
const editMode = createAsyncThunk('modes/editMode', async (data:any) => {
  const {value, id } = data;
  try {
    const response = await Mode_Edit(value, id);
    if (response?.ok) {
    //   dispatch(fetchModes());
    }
    return response; // You can return the response if needed
  } catch (error) {
    throw error;
  }
});
const deleteMode =createAsyncThunk(
	'mode/deleteMode', async (id:any)=>{
		try {
			
			const response =await Mode_delete(id as any)
			console.log(response)
			
			// return response
		} catch (error) {
			console.log(error)
		}
	}
)

const modeSlice = createSlice({
  name: 'modeSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchModes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchModes.fulfilled, (state, action) => {
        state.loading = false;
        state.allModes = action.payload;
      })
    
  },
});

export default modeSlice.reducer;
export { fetchModes, addMode, editMode,deleteMode };
